<script setup>
import semverGt from "semver/functions/gt"
import testData from "~/utils/profiles/profilesTest"
import { defineAsyncComponent } from "vue"
import { useGlobalStore } from "@/stores/global"

const Updating = defineAsyncComponent(
  () => import("@/components/Init/Updating.vue")
)

const store = useGlobalStore()
const runtimeConfig = useRuntimeConfig()

const envPackageVersion = runtimeConfig.public.clientVersion

function init() {
  // Close app nav drawer if open.
  store.appNavDrawer = false
  // Load test profiles if testing.
  if (runtimeConfig.public.seedTestData) {
    // Intro message was skipped so set this to false here.
    store.newUser = false
    // Only load profiles if none exist.
    if (store.profiles.length === 0) {
      store.loadProfiles(testData)
    }
  } else {
    // Initialise profile data if profiles object is missing
    // (local storage was deleted by another means).
    if (typeof store.profiles === "undefined") {
      store.initialiseProfileData()
    }
    if (store.currentAttacker === null) {
      store.setCurrentAttacker({})
    }
    if (store.currentDefender === null) {
      store.setCurrentDefender({})
    }
    store.readOnlyProfiles = false
  }
}

init()

const runningLatestVersion = store.appVersionLastSeen === envPackageVersion
const latestSuffix = runningLatestVersion ? "(latest)" : ""

console.info("Last seen version:", store.appVersionLastSeen, latestSuffix)
if (!runningLatestVersion) {
  console.info("Latest version:", envPackageVersion)
}

// New users get the adhoc profile UI by default.
if (store.newUser) {
  store.setProfileUi({
    profileType: "attacker",
    profileUiType: "adhoc",
  })
  store.setProfileUi({
    profileType: "defender",
    profileUiType: "adhoc",
  })
}

if (
  store.appVersionLastSeen !== "0.0.0" &&
  semverGt(envPackageVersion, store.appVersionLastSeen)
) {
  store.updateInProgress = true
} else {
  // This is handled by RunUpdates.vue if this code isn't reached.
  store.updateCurrentProfiles()
  store.syncAppVersions()
  store.updateLastSeen()
}
</script>

<template>
  <div>
    <Updating v-if="store.updateInProgress" />
  </div>
</template>
